import React from 'react'
import { Order, OrderStatus } from '../../service/types/order'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { fDateTime } from '../../utils/format-time'
import Label, { LabelColor } from 'src/components/label'
import { fCurrencyTl } from '../../utils/format-number'
import { OrderRowActions } from '../../dashboard/pending/order-confirm-button'
import Tooltip from '@mui/material/Tooltip'

type Props = {
    order: Order,
    onRefresh: () => void,
}

export default function OrderRow({ order, onRefresh }: Props) {
    return <TableRow>
        <TableCell sx={{ minWidth: 150 }}>{order.customer.name}</TableCell>
        <TableCell
            sx={{ width: 100, maxWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            <Tooltip title={order.address.address} arrow placement={'bottom-start'}>
                <span>{order.address.address}</span>
            </Tooltip>
        </TableCell>
        <TableCell sx={{ minWidth: 150 }}><OrderStatusLabel status={order.status} /></TableCell>
        <TableCell sx={{ minWidth: 120 }}>{fCurrencyTl(order.total)}</TableCell>
        <TableCell sx={{ minWidth: 120 }}><DiscountLabel order={order}/></TableCell>
        <TableCell sx={{ minWidth: 120 }}>{fCurrencyTl(order.finalPrice)}</TableCell>
        <TableCell sx={{ minWidth: 180 }}>{fDateTime(order.pendingTime)}</TableCell>
        <TableCell><SuitabilityTimeLabel time={order.suitableTime} /></TableCell>
        <TableCell sx={{ minWidth: 150 }}><PaymentTypeLabel type={order.paymentType.id} /></TableCell>
        <TableCell sx={{ minWidth: 150 }}>{order.driver?.name ?? 'Atanmadı'}</TableCell>
        <TableCell sx={{ minWidth: 560 }}><OrderRowActions order={order}
                                                           onRefresh={onRefresh} /> </TableCell>
    </TableRow>
}

export function PaymentTypeLabel({ type }: { type: string }) {
    let color: LabelColor = 'primary'
    let text = ''
    if (type === 'CASH') {
        color = 'success'
        text = 'Nakit'
    } else if (type === 'CREDIT_CARD') {
        color = 'info'
        text = 'Kredi Kartı'
    } else if (type === 'CREDIT_CARD_DOOR') {
        color = 'warning'
        text = 'Kapıda Kredi Kartı'
    }
    return <Label color={color} variant="soft">{text}</Label>
}


export function OrderStatusLabel({ status }: { status: OrderStatus }) {
    let color: LabelColor = 'primary'
    let text = ''
    if (status === OrderStatus.PENDING) {
        color = 'warning'
        text = 'Beklemede'
    } else if (status === OrderStatus.DELIVERED) {
        color = 'success'
        text = 'Tamamlandı'
    } else if (status === OrderStatus.CANCELLED) {
        color = 'error'
        text = 'İptal Edildi'
    } else if (status === OrderStatus.CONFIRMED) {
        color = 'info'
        text = 'Onaylandı'
    } else if (status === OrderStatus.ONWAY) {
        color = 'info'
        text = 'Yolda'
    }
    return <Label color={color} variant="soft">{text}</Label>
}

export function SuitabilityTimeLabel({ time }: { time?: number }) {
    if (time === undefined || time < 1000) {
        return <Label color="success" variant="soft">Hemen</Label>
    }
    if (time > Date.now()) {
        return <Label color="warning" variant="soft">{fDateTime(time)}</Label>
    }
    return <Label color="info" variant="soft">{fDateTime(time)}</Label>
}

export const DiscountLabel = ({ order }: { order: Order }) => {
    if (order.discount) {
        return <Label color={'success'} variant={'soft'}>
            {order.discount.title} - {fCurrencyTl(order.discount.discount)}
        </Label>
    }
}