import React, { useEffect, useState } from 'react'
import { useNotify } from 'react-admin'
import Stack from '@mui/material/Stack'
import { useOrderService } from '../../../service/order-service'
import { useBoolean } from '../../../hooks/use-boolean'
import Container from '@mui/material/Container'
import OrderDetailsToolbar from './order-details-toolbar'
import LinearProgressView from '../../../common/linear-progress-view'
import Grid from '@mui/material/Unstable_Grid2'
import OrderDetailsItems from './order-details-item'
import OrderDetailsHistory from './order-details-history'
import OrderDetailsInfo from './order-details-info'
import { useLocation, useNavigate } from 'react-router-dom'
import { Order, OrderHistory } from '../../../service/types/order'

export const OrderDetailView = () => {
    const { state } = useLocation()
    const [order, setOrder] = useState<Order>()
    const orderService = useOrderService()
    const loading = useBoolean(true)
    const notify = useNotify()
    const [orderHistory, setOrderHistory] = useState<OrderHistory>()
    const router = useNavigate()
    const fetchOrder = async () => {
        loading.onTrue()
        await orderService.get(state.id as string)
            .then(setOrder)
            .catch(() => {
                notify('Sipariş getirilirken bir hata oluştu lütfen tekrar deneyin.', { type: 'error' })
            })
        await orderService.getHistory(state.id as string)
            .then(setOrderHistory)
            .catch(() => {
                notify('Sipariş geçmişi getirilirken bir hata oluştu lütfen tekrar deneyin.', { type: 'error' })
            })
            .finally(loading.onFalse)
    }

    useEffect(() => {
        if (state?.id) {
            void fetchOrder()
        } else {
            notify('Sipariş bilgileri bulunamadı.', { type: 'error' })
            router('/')
        }
    }, [state])

    if (loading.value || !order) {
        return <LinearProgressView />
    }

    return <Container maxWidth={false} sx={{ mt: 5 }}>
        <OrderDetailsToolbar
            orderNumber={order.id}
            createdAt={order.pendingTime}
            status={order.status}
            order={order}
            onRefresh={fetchOrder}
        />

        <Grid container spacing={3}>
            <Grid xs={12} md={8}>
                <Stack spacing={3} direction={{ xs: 'column-reverse', md: 'column' }}>
                    <OrderDetailsItems
                        items={order.items}
                        shipping={order.deliveryFee ?? 0}
                        subTotal={order.total}
                        discount={order.discount}
                        totalAmount={order.finalPrice}
                    />

                    {orderHistory && <OrderDetailsHistory history={orderHistory} />}
                </Stack>
            </Grid>

            <Grid xs={12} md={4}>
                <OrderDetailsInfo
                    customer={order.customer}
                    delivery={order.driver}
                    paymentType={order.paymentType}
                    note={order.customerNote ?? ''}
                    paymentInfo={order.paymentInfo}
                    suitableTime={order.suitableTime}
                    shippingAddress={order.address}
                />
            </Grid>
        </Grid>
    </Container>
}