import { TableNoData, TablePaginationCustom, useTable } from '../../components/table'
import React from 'react'
import { Order } from '../../service/types/order'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHeadCustom from '../../components/table/table-head-custom'
import TableBody from '@mui/material/TableBody'
import OrderRow from './order-row'
import { SortPayload } from 'ra-core'

const orderHeadLabel = [
    {
        id: 'customer',
        label: 'Müşteri',
    },
    {
        id: 'address',
        label: 'Adres',
    },
    {
        id: 'status',
        label: 'Durum',
    },
    {
        id: 'total',
        label: 'Ara Toplam',
    },
    {
        id: 'discount',
        label: 'İndirim',
    },
    {
        id: 'finalPrice',
        label: 'Toplam',
    },
    {
        id: 'pendingTime',
        label: 'Oluşturulma Tarihi',
    },
    {
        id: 'suitableTime',
        label: 'Teslimat Uygun Zamanı',
    },
    {
        id: 'paymentType',
        label: 'Ödeme Türü',
    },
    {
        id: 'driver',
        label: 'Sürücü',
    },
    {
        id: 'actions',
        label: 'İşlemler',
        notSortable: true,
    },
]
type Props = {
    orders: Order[],
    refresh: () => void,
    page?: number,
    rowsPerPage?: number,
    onChangePage?: (page: number) => void,
    onRowsPerPageChange?: (rowsPerPage: number) => void,
    total?: number,
    onSort?: (payload: SortPayload) => void,
    order?: 'ASC' | 'DESC',
    orderBy?: string,
}
export default function OrderTable({
                                       orders,
                                       refresh,
                                       page,
                                       rowsPerPage,
                                       onRowsPerPageChange,
                                       onChangePage,
                                       total,
                                       onSort,
                                       order, orderBy,
                                   }: Props) {
    const table = useTable()

    const data = page && rowsPerPage ? orders : orders.slice(table.page * table.rowsPerPage, table.page * table.rowsPerPage + table.rowsPerPage)

    if (data.length === 0) {
        return <TableNoData notFound={true} />
    }

    return <>
        <TableContainer sx={{ overflowY: 'unset', maxWidth: '90vw', overflowX: 'auto' }}>
            <Table size={'small'}>
                <TableHeadCustom
                    order={order === 'ASC' ? 'asc' : 'desc' ?? table.order}
                    orderBy={orderBy ?? table.orderBy}
                    headLabel={orderHeadLabel}
                    rowCount={total ?? orders.length}
                    numSelected={table.selected.length}
                    onSort={(field) => {
                        if (onSort) {
                            onSort({ field, order: table.order === 'asc' ? 'ASC' : 'DESC' })
                        } else {
                            table.onSort(field)
                        }

                    }}
                />
                <TableBody>
                    {data.map((row) => <OrderRow key={row.id} order={row} onRefresh={refresh} />)}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePaginationCustom
            count={total ?? orders.length}
            page={page ? page - 1 : table.page}
            rowsPerPage={rowsPerPage ?? table.rowsPerPage}
            onPageChange={(_, page) => {
                if (onChangePage) {
                    onChangePage(page + 1)
                } else {
                    table.onChangePage(_, page)
                }
            }}
            onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (onRowsPerPageChange) {
                    onRowsPerPageChange(Number(event.target.value))
                } else {
                    table.onChangeRowsPerPage(event)
                }
            }
            }
            dense={true}
            onChangeDense={table.onChangeDense}
            labelRowsPerPage="Satır"
        />
    </>
}